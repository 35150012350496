<template>
  <div>
    <amplify-authenticator username-alias="email"></amplify-authenticator>
  </div>
</template>

<script>
import Vue from 'vue';
import Vuex from 'vuex';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import store from '../store.vue'
Vue.use(Vuex);
Vue.use(store);

export default {
  name: 'AuthState',
  data: function () {
    return {  
    };
  },
  created() {  
   // authentication state managament  
    onAuthUIStateChange((state, user) => {  
      // set current user and load data after login  
      if (state === AuthState.SignedIn) {  
        this.$store.store.commit('setUser', user);  
      }  
    }) 
  }  
}
</script>