<template>
<div class="container">
    <h1 class = "mt-3 my-4" align="left" style="color:#882255">About Us</h1>
    <p class="my-4">
        Hello! My name is Ayana Bharadwaj, and I am a high schooler from the Seattle area who loves to hang out with her dog, play board 
        games (not with the dog), write cool programs, learn languages, and listen to music. Oh, I almost forgot - I’m also a black belt 
        in Taekwondo.
    </p>
    <p class="my-4">
        Some of my fondest memories growing up were playing Sudoku with my grandmother, who was addicted to the game. She also played 
        other math games with me, some that she made up herself; she was a math teacher after all.  In hindsight, I realized that the 
        games I played with my grandmother probably built a stronger foundation in math than what I was learning in school. 
    </p>
    <p class="my-4">
        During my freshman year, inspiration hit me when I saw the Foldscope. Although it may seem unrelated, the philosophy behind it 
        can be applied to board games.
    </p>
    <p class="my-4">
        In 2014, while a PhD student at Stanford, Jim Cybulski, along with Professor Manu Prakash created the Foldscope with the goal of 
        making the best possible microscope for less than a dollar (yes, you read that right – less than a dollar!). That turned my idea 
        of what it means to be an inventor is on its head. I went from imagining a Tony Stark character churning out high-tech gadgets to 
        someone who works to simplify something and make it more accessible. This can sometimes be a lot harder than building a complex 
        system. In Prof. Prakash’s TED talk, he demonstrates how <i>“even though [the Foldscope] looks like a simple toy, the aspects of 
        engineering that go in something like [it] are fairly sophisticated.”</i>
    </p>
    <p class="my-4">
        That’s how Rock Paper Scissors Games was born.
    </p>
    <p class="my-4">
        I believe every child should have access to games that help them learn in new ways. Is there any kid who wouldn’t rather play 
        Scrabble than do their spelling homework? But the reality is that many people around the world cannot afford to pay $20 for a 
        board game. If you think about it, the value of a board game lies in the logic of the game rather than the cardboard board, 
        factory-made plastic pieces, and neatly printed packaging (not to mention the ten different plastic bags). By removing all of 
        this, I stripped down board games to their core for Rock Paper Scissors Games.
    </p>
    <p class="my-4">
        If you’re wondering about the name – it is a full list of all the materials you need. You can use rocks for playing pieces, paper 
        printouts for the board, cards, etc. and scissors to make necessary cuts in the paper.
    </p>
</div>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
export default {
    name: 'AboutPage',
    data: function () {
        return {
        };
    }
}
</script>