/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:d0422831-df31-4a7e-ab53-eece036bcf5f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_PIsNss56t",
    "aws_user_pools_web_client_id": "8educeu14g0hlcjbgof60oehg",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gamesDDBTable-dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "boardgamesb4782d4449c245709e21fe51ef5e6d69150636-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "gamesApi",
            "endpoint": "https://wltqjz6oh5.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
