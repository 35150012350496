<template>
  <div>
    <div class="container-fullwidth">
        <b-navbar toggleable="sm" type="dark" variant="dark" id="navbar">
          <a class="navbar-brand" href="#" @click="homePage">
            <img src="./assets/RPS_Logo.png" height="50px">
            RPS Games
          </a>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item href="#" id="links"  @click="gamePage">Games</b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav>
              <b-nav-item href="#" id="links" @click="submitPage">Contact Us</b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav>
              <b-nav-item href="#" id="links" @click="aboutPage">About Us</b-nav-item>
            </b-navbar-nav>
            <b-button v-if="$store.store.state.user === undefined" class="ml-auto" @click="signIn">Sign in</b-button>
            <div v-if="$store.store.state.user" class="ml-auto">
              <amplify-sign-out></amplify-sign-out>
              <div style="color:white">Hello, {{$store.store.state.user.attributes.email}}</div>
            </div>
          </b-collapse>
        </b-navbar>
      </div>
    <component :is="mainComponent" />
  </div>
</template>

<script>
import Vue from 'vue'
import AuthState from './components/AuthState.vue'
import GamePage from './components/GamePage.vue'
import HomePage from './components/HomePage.vue'
import AboutPage from './components/AboutPage.vue'
import SubmitPage from './components/SubmitPage.vue'
import store from './store.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import Vuex from 'vuex';
Vue.use(Vuex);
import { Amplify, API } from 'aws-amplify';


export default {
  name: 'App',
  data: function () { return { mainComponent: 'HomePage' }; },
  store,
  components: {
    Amplify,
    BootstrapVue,
    IconsPlugin,
    AuthState,
    HomePage,
    GamePage,
    AboutPage,
    SubmitPage
  },
  methods: {
    signIn: function() {
      this.mainComponent = AuthState;
    },
    homePage: function() {
      this.mainComponent = HomePage;
    },
    gamePage: function() {
      this.mainComponent = GamePage;
    },
    aboutPage: function() {
      this.mainComponent = AboutPage;
    },
    submitPage: function() {
      this.mainComponent = SubmitPage;
    }
  },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.$store.store.commit('setUser', authData);
    });
  },
  mounted() {
    this.$nextTick(
      function () {
        API.get('gamesApi', '/games/top_picks', {})
			.catch(err => console.log(err))
			.then(res => this.$store.store.commit('setGames', JSON.parse(res.body)));
      }
    )
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  }
}
</script>

