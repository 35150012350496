<template>
    <div>
        <!--Browsing mode, either top picks are shown, or filtered search-->
        <div v-if="!detail" class="container">
            <h1 class = "mt-3" align="left" style="color:#882255">All Games</h1>
            <div class="row">
                <h6 class="mx-2">Filter by:</h6>
                <div class="mx-2">
                    <select v-model="age">
                        <option v-for="(x, i) in ages" v-bind:value="i" v-bind:key="i">
                        {{ x }}
                        </option>
                    </select>
                </div>
                <div class="mx-2">
                    <select v-model="subject">
                        <option v-for="(x, i) in subjects" v-bind:value="i" v-bind:key="i">
                        {{ x.name }}
                        </option>
                    </select>
                </div>
                <b-button align="left" @click="getGames">Get Games</b-button>
            </div>
            <div class="row">
                <div v-if="$store.store.state.games.length==0">
                    <h4>Games for this age group and category coming soon!</h4>
                </div>
                <div v-for="(game, index) in $store.store.state.games" v-bind:key="index" class="col-4">
                    <b-card class="mt-2">
                        <h4  style="color:#bbaa55">{{game.title}} <img  class = "float-right" :src = "$store.store.state.publicPath + subjects[game.sub].logo" height = "50px"></h4>
                        <h6 style="color:#77bbdd">By: {{game.author}}<b-icon v-if="game.author==='RPS Original'" icon="check2-circle" class="ml-1"></b-icon></h6>
                        <b-card-text>
                            Subject: {{subjects[game.sub].name}}, Age Group: {{ages[game.age]}}
                        </b-card-text>

                        <b-card-text>{{game.tag}}</b-card-text>

                        <b-link @click="getPDF(game.author, game.title)" class="card-link">Download Game</b-link>
                        <b-link href="#" class="card-link" @click="detail=game">View Details</b-link>
                    </b-card>
                </div>
            </div>
        </div>
        <!--Shows page with details of a particular game and its author-->
        <div v-if="detail" class="container">
            <h1 class="mt-3" style="color:#882255">{{detail.title}}</h1>
            <h6 style="color:#ddcc77">{{detail.author}}<b-icon v-if="detail.author==='RPS Original'" icon="check2-circle" class="ml-1"></b-icon></h6>
            <div class="row">
                <div class="col-6">
                    <b-card>
                        <h5>About the game</h5>
                        <p>Category</p>
                        <ul>
                            <li>Age Group: {{ages[detail.age]}}</li>
                            <li>Subject: {{subjects[detail.sub].name}}</li>
                        </ul>
                        <p>{{detail.tag}}</p>
                        <p>{{detail.desc}}</p>
                    </b-card>
                </div>
                <div class="col-6">
                    <b-card>
                        <h5>About the author</h5>
                        <b-button v-if="!showAuthor" @click = "getAuthor(detail.author)">Load Author Details</b-button>
                        <div v-if="showAuthor">
                            <p>{{author.name}}:</p>
                            <p>{{author.desc}}</p>
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="row">
                <b-link @click="getPDF(detail.author, detail.title)" class="card-link mx-2">Download Game</b-link>
                <b-link href="#" class="card-link mx-2" @click="detail=undefined; showAuthor = false">Back to all games</b-link>
            </div>
            <!--List other games by author if author data is loaded-->
            <div v-if="showAuthor">
                <div class="row">
                    <h5>More games by this author:</h5>
                </div>
                <div class="card-group" v-if="showAuthor"> 
                    <div v-for="(game, index) in author.games.filter(x => x.title != detail.title)" v-bind:key="index" class="col-4">
                        <b-card class="mt-2">
                            <h4  style="color:#bbaa55">{{game.title}} <img  class = "float-right" :src = "$store.store.state.publicPath + subjects[game.sub].logo" height = "50px"></h4>
                            <b-card-text>
                                Subject: {{subjects[game.sub].name}}, Age Group: {{ages[game.age]}}
                            </b-card-text>

                            <b-card-text>{{game.tag}}</b-card-text>

                            <b-link href="#" class="card-link">Download Game</b-link>
                            <b-link href="#" class="card-link" @click="detail=game">View Details</b-link>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Vuex from 'vuex';
    import store from '../store.vue'
    Vue.use(Vuex);
    Vue.use(store);
    import { Storage, API } from 'aws-amplify';
    export default {
        name: 'GamePage',
        data: function () {
            return {
                author: {},
                showAuthor: false,
                age: 0,
                subject: 0,
                detail: undefined,
                ages: ['Preschool', 'Early Reader', 'Elementary'],
                subjects: [ { name: 'Math', logo: 'MathLogo.jpg' },
                    { name: 'Science', logo: 'SciLogo.jpg' },
                    { name: 'Critical Thinking', logo: 'CTLogo.jpg' }]
            };
        },
        store,
        methods: {
            getGames() {
                var param = this.subject.toString().padStart(3, '0') + 'X' + this.age;
                var apiName = 'gamesApi';
                var path = '/games/by_category/' + param;
                //commits to store an array of games in alphabetical order in the age and subject category
                API.get(apiName, path, {})
                    .catch(err => console.log(err))
                    .then(res => this.$store.store.commit('setGames', JSON.parse(res.body)));
            },
            getAuthor(author) {
                var apiName = 'gamesApi';
                var path = '/games/by_author/' + author;
                //this.author is set to an object with a name, description and array of games other than the one shown in detail
                API.get(apiName, path, {})
                          .catch(err => console.log(err))
                          .then(res => {this.author = JSON.parse(res.body);});
                this.showAuthor = true;
            },
            getPDF(author, game) {
                var file = author + '_' + game + '.pdf';
                var url = "";
                var a = {};
                Storage.get(file).then(result => {
                    url = result;
                    //Create and click an element with the PDF url
                    a = document.createElement('a');
                    a.href = url;
                    a.target = '_blank';
                    a.click();
                });
                return a;
            }
        }
    }
</script>