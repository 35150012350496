<template>
<div class="container">
    <h1 class = "mt-3 my-4" align="left" style="color:#882255">Contact Us</h1>
    <p>You can reach us at hello@rpsgames.org with comments, questions or game submissions! We'd love to hear from you!</p>
    <p>To submit a game, please include the following:</p>
    <ul> 
        <li>Your name as you would like it to be displayed on the site</li>
        <li>A description of yourself (30 words or less)</li>
        <li>The title of the game</li>
        <li>The subject (Math, Science, or Critical Thinking)</li>
        <li>The age group (Preschool, Early Reader or Elementary)</li>
        <li>A short tagline for the game (15 words or less)</li>
        <li>A description of the rules (50 words or less)</li>
        <li>A PDF file of the game including rules, board, and pieces to cut out</li>
    </ul>
</div>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
export default {
    name: 'AboutPage',
    data: function () {
        return {
        };
    }
}
</script>