<script>
	import Vue from 'vue';
	import Amplify from 'aws-amplify';
	import Vuex from 'vuex';
	Vue.use(Vuex);
	Vue.config.devtools = true;

	const store = new Vuex.Store({
		state: {
			user: Amplify.currentAuthenticatedUser,
			publicPath: process.env.BASE_URL,
			games:  []
		},
		mutations: {
			setUser: function(state, newUser) {
				state.user = newUser;
			},
			setGames: function(state, gameList) {
				state.games = gameList;
			}
		}
	});
	export default {
		store
	}
</script>