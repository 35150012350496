<template>
    <div>
        <div class = "container-fullwidth"> 
            <b-card style="color:#77bbdd" overlay :img-src="$store.store.state.publicPath + 'sitePic.png'">
            </b-card>
        </div>
        <div class = "container-fullwidth">
                <b-card border-variant="dark">
                    <b-card-text class="lead text-center">Rock Paper Scissors Games (RPS Games for short) is intended to provide free educational board games to all students.
                        Learning by playing games is fun, effective, and should be easily accessible to all students. 
                        That's why our games made by students, for students are free to download and play.
                    </b-card-text>
                </b-card>
            </div>
        <div class = "container-fullwidth">
            <div class = "row card-group">
                <b-card class="col-4">
                    <h5  style="color:#882255">Games You Can Easily Print<img  class = "float-right" :src="$store.store.state.publicPath + 'printable.png'" height = "70px"></h5>
                    <ul>
                        <li>All games are 100% free</li>
                        <li>Print in color or black and white</li>
                        <li>Minimal materials required</li>
                    </ul>
                </b-card>
                <b-card class="col-4">
                    <h5  style="color:#882255">Build Useful Skills<img  class = "float-right" :src="$store.store.state.publicPath + 'useful.png'" height = "70px"></h5>
                    <ul>
                        <li>Learn math, science and critical thinking in a fun way</li>
                        <li>Games catered to different age groups</li>
                        <li>Minimal reading skills required</li>
                    </ul>
                </b-card>
                <b-card class="col-4">
                    <h5  style="color:#882255">By Students, For Students<img  class = "float-right" :src="$store.store.state.publicPath + 'students.png'" height = "70px"></h5>
                    <ul>
                        <li>Students learn how to create their own games</li>
                        <li>Students can be inspired by each other</li>
                        <li>Develops creativity as well as "left brain" skills</li>
                    </ul>
                </b-card>
            </div>
        </div>
        <div class = "container-fullwidth">
            <b-card border-variant="dark">
                    <b-card-text class="lead text-center">Awards and Recognition
                    </b-card-text>
                </b-card>
            <div class = "row" style = "justify-content:center">
                <img :src="$store.store.state.publicPath + 'AwardsLogos.JPG'"/>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuex from 'vuex';
import store from '../store.vue'
Vue.use(Vuex);
Vue.use(store);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
export default {
    name: 'HomePage',
    data: function () {
        return {
        };
    },
    store
}
</script>